import React from "react"
import SEO from "../components/seo"
import LayoutRoot from "../components/LayoutRoot"
import Layout from "../components/layout"
import ResumeMain from "../components/resume/ResumeMain"

const ResumePage = () => {
  return (
    <div>
      <SEO title="Resume" />
      <LayoutRoot>
        <Layout>
          <ResumeMain />
        </Layout>
      </LayoutRoot>
    </div>
  )
}

export default ResumePage
